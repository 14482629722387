import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async'; // Import react-helmet
import { FaUser, FaEnvelope, FaCommentDots, FaTag } from 'react-icons/fa';
import {
    ContactContainer,
    Title,
    Description,
    ContactForm,
    Input,
    InputField,
    TextArea,
    Icon,
    SubmitButton
} from './ContactElements';
import Notification from './Notification';

const Contact = () => {
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validateForm = async (event) => {
        event.preventDefault();
        let newErrors = {};

        const name = event.target[0].value;
        const email = event.target[1].value;
        const subject = event.target[2].value;
        const message = event.target[3].value;

        if (!name) newErrors.name = 'Namn är obligatoriskt';
        if (!email.includes('@')) newErrors.email = 'Ogiltig e-postadress';
        if (!subject) newErrors.subject = 'Ämnet är obligatoriskt';
        if (!message) newErrors.message = 'Meddelande är obligatoriskt';

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                // Uppdaterad fetch URL för lokal backend
                const response = await fetch('https://kryptotrading.se/api/contact', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ name, email, subject, message }),
                });

                if (response.ok) {
                    setNotification({ message: 'Meddelandet har skickats!', type: 'success' });
                    setIsSubmitted(true);
                    event.target.reset();
                    setTimeout(() => setIsSubmitted(false), 5000);
                } else {
                    setNotification({ message: 'Ett fel inträffade. Försök igen senare.', type: 'error' });
                }
            } catch (error) {
                console.error('Error:', error);
                setNotification({ message: 'Kunde inte skicka meddelandet. Kontrollera din anslutning.', type: 'error' });
            }
        } else {
            setNotification({ message: 'Formuläret är inte komplett, kontrollera fälten.', type: 'error' });
        }
    };

    const closeNotification = () => {
        setNotification(null);
    };

    return (
        <ContactContainer>
            {/* Helmet for SEO Metadata */}
            <Helmet>
                <title>Kontakta Oss - Kryptotrading.se</title>
                <meta name="description" content="Kontakta oss på Kryptotrading.se för alla dina frågor om kryptovalutahandel och plattformar. Vi finns här för att hjälpa dig!" />
                <meta name="keywords" content="kontakta oss, kryptovalutor, kryptovalutahandel, support, frågor, kryptotrading" />
                <meta property="og:title" content="Kontakta Oss - Kryptotrading.se" />
                <meta property="og:description" content="Har du frågor om kryptovalutor eller behöver support? Fyll i vårt kontaktformulär så återkommer vi så snart som möjligt." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.kryptotrading.se/kontakt" />
                <meta property="og:image" content="https://www.kryptotrading.se/images/contact-og-image.jpg" />

                <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "ContactPage",
                    "name": "Kontakta Oss - Kryptotrading.se",
                    "description": "Har du frågor om kryptovalutor eller behöver support? Fyll i vårt kontaktformulär så återkommer vi så snart som möjligt.",
                    "url": "https://www.kryptotrading.se/kontakt",
                    "mainEntity": {
                        "@type": "Organization",
                        "name": "Kryptotrading.se",
                        "url": "https://www.kryptotrading.se",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "Support",
                            "email": "support@kryptotrading.se",
                            "availableLanguage": ["Svenska", "Engelska"]
                        }
                    }
                })}
                </script>
            </Helmet>

            <Title>Kontakta Oss - Kryptotrading.se</Title>
            <Description>Har du frågor eller förfrågningar? Fyll i formuläret nedan så återkommer vi till dig så snart som möjligt.</Description>

            <ContactForm noValidate onSubmit={validateForm}>
                <Input>
                    <Icon><FaUser aria-hidden="true" /></Icon>
                    <InputField type="text" placeholder="Ditt namn" aria-label="Ditt namn" disabled={isSubmitted} />
                </Input>
                <Input>
                    <Icon><FaEnvelope aria-hidden="true" /></Icon>
                    <InputField type="email" placeholder="Din e-postadress" aria-label="Din e-postadress" disabled={isSubmitted} />
                </Input>
                <Input>
                    <Icon><FaTag aria-hidden="true" /></Icon>
                    <InputField type="text" placeholder="Ämne" aria-label="Ämne" disabled={isSubmitted} />
                </Input>
                <Input>
                    <Icon><FaCommentDots aria-hidden="true" /></Icon>
                    <TextArea placeholder="Ditt meddelande" rows="5" aria-label="Ditt meddelande" disabled={isSubmitted} />
                </Input>
                <SubmitButton type="submit" aria-label="Skicka" disabled={isSubmitted}>Skicka</SubmitButton>
            </ContactForm>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={closeNotification}
                />
            )}
        </ContactContainer>
    );
};

export default Contact;

