// Importera styled components
import styled from 'styled-components';

export const GetStartedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  margin: 0 auto;
  padding: 40px 20px;
  background: linear-gradient(180deg, #f7fbff, #ffffff);

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

export const MainContent = styled.div`
  flex: 3;
  padding: 20px;
  background-color: transparent;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const HeaderSection = styled.div`
  margin-bottom: 20px;

  h1 {
    font-size: 2.5rem;
    color: #1a73e8;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;

export const IntroParagraph = styled.p`
  color: #3b3b3b;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const DateInfo = styled.p`
  color: #1a73e8;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const ImageSection = styled.div`
  margin: 30px 0 40px 0;
  text-align: center;

  img {
    max-width: 60%;
    height: auto;

    @media (max-width: 768px) {
      max-width: 80%;
      margin-bottom: -20px; /* Minskar mellanrummet efter bilden på mobil */
    }
  }
`;


export const TableOfContents = styled.div`
  flex: 1;
  margin-left: 20px;
  position: sticky;
  top: 120px;
  background: linear-gradient(180deg, #3b82f6, #1a73e8);
  padding: 20px;
  border-radius: 12px;
  border: 2px solid rgba(59, 130, 246, 0.3);
  height: auto;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.6rem;
    margin-bottom: 10px;
    color: #ffffff;
    text-align: left;
    padding-left: 10px;

    @media (max-width: 768px) {
      font-size: 1.4rem;
      text-align: center;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: left;

    li {
      margin-bottom: 10px;
      line-height: 1.6;

      a {
        color: #ffffff;
        text-decoration: none;
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          color: #ffffff;
        }
      }
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    position: static;
    margin-top: 20px;
    padding: 10px;
    display: ${({ isExpanded }) => (isExpanded ? "block" : "none")}; /* För att hantera expanderbar vy */
  }
`;

export const Section = styled.div`
  margin-bottom: 30px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const CompactParagraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #37474f;
  margin-bottom: 15px;
  text-align: justify;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1rem;
    max-width: 90%;
    margin-bottom: 10px;
  }
`;

export const CenteredSectionHeader = styled.h2`
  font-size: 2rem;
  color: #1a73e8;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-top: 10px; /* Minskad toppmarginal för att minska avståndet från dividern */
  }
`;


export const Divider = styled.div`
  height: 2.5px;
  background-color: #0d47a1;
  margin: 40px 0;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

export const PlatformSection = styled.div`
  margin-top: 40px;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const PlatformImage = styled.img`
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    max-width: 150px;
  }
`;

export const AffiliateLink = styled.a`
  display: block;
  font-size: 1.1rem;
  color: #1a73e8;
  margin-top: 10px;
  text-decoration: underline;

  &:hover {
    color: #00e0a1;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const SubHeader = styled.h3`
  font-size: 1.8rem;
  color: #1a73e8;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #455a64;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

export const SectionText = styled.p`
  font-size: 1.3rem;
  line-height: 1.6;
  color: #37474f;
  margin-bottom: 15px;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
`;

export const CryptoIconSection = styled.div`
  margin: 15px 0 20px 0;
  display: flex;
  align-items: center;

  img {
    max-width: 40px;
    height: auto;
    margin-right: 15px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    img {
      margin: 0 0 10px 0;
    }
  }
`;

export const CryptoText = styled.div`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #37474f;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: left;
  }
`;

