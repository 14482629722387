import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  background-color: #101522;
  padding: 2rem 0;
  text-align: center;
  color: white;
  width: 100%;
`;

export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Mobil 2x2-layout */
    justify-items: center; /* Horisontell centrering */
    align-items: center; /* Vertikal centrering */
    text-align: center;
    margin-bottom: 12px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Desktop 1x4-layout */
  gap: 15px; /* Avstånd mellan länkar på desktop */
  justify-content: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Mobil layout */
    justify-items: center; /* Säkerställ centrering även här */
    align-items: center;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  margin: 0; /* Inga extra marginaler för mobil */

  @media screen and (max-width: 768px) {
    margin: 0; /* Tar bort ojämnheter för mobil */
    margin-left: 3.2rem;
  }
`;


export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #00FFFF; /* Changed hover color to cyan */
    transition: 0.3s ease-out;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-top: 16px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 24px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const PartnerLogo = styled.img`
  width: 60px;
  margin: 0 35px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) {
    width: 50px;
    margin: 0 10px;
  }
`;

export const FooterText = styled.div`
  max-width: 800px;
  text-align: center;
  margin: 20px 0;

  p {
    margin: 5px 0;
    color: #fff;
    font-size: 16px; /* Standardstorlek för desktop */
    
    @media screen and (max-width: 768px) {
      font-size: 14px; /* Förminskad textstorlek för mobil */
      line-height: 1.4; /* Bra avstånd mellan rader för läsbarhet */
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #00FFFF; /* Hoverfärg cyan */
      }
    }
  }
`;

