// src/components/FAQ/index.js

import React, { useState } from 'react';
import {
  FAQContainer,
  FAQHeader,
  FAQItem,
  Question,
  Answer,
  ToggleIcon,
  RightArrow,
  DownArrow
} from './FAQElements'; // Adjust import to bring in new icons

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Schema för vanliga frågor för SEO
  const faqItems = [
    {
      question: 'Vad är skillnaden mellan en digital valuta och en kryptovaluta?',
      answer: 'Skillnaden mellan en digital valuta och en kryptovaluta är att den sistnämnda är decentraliserad...'
    },
    {
      question: 'Hur många olika typer av wallets för kryptovaluta finns det?',
      answer: 'Det finns fem huvudsakliga typer av wallets, eller digitala plånböcker, för kryptovaluta: skrivbordsbaserade, mobilbaserade...'
    },
    {
      question: 'Vilken var den första kryptovalutan?',
      answer: 'Den första kryptovalutan är Bitcoin som skapades 2009 av Satoshi Nakamoto...'
    },
    {
      question: 'Är kryptovalutor riktiga pengar?',
      answer: 'Ja, kryptovalutor kan användas som betalningsmedel, men de skiljer sig från traditionella fiatvalutor genom att de är digitala...'
    },
    {
      question: 'Hur många kryptovalutor finns det?',
      answer: 'Det finns över 10 000 olika kryptovalutor globalt, men de flesta av dessa har liten eller ingen marknadsaktivitet...'
    }
  ];

  // Skapar strukturerad data för FAQ för SEO
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqItems.map((item) => ({
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }))
  };

  return (
    <FAQContainer>
      <FAQHeader>Vanliga Frågor</FAQHeader>
      
      {/* Lägger till strukturerad data i form av ett script-element för SEO */}
      <script type="application/ld+json">
        {JSON.stringify(faqSchema)}
      </script>

      {faqItems.map((item, index) => (
        <FAQItem key={index} itemScope itemType="https://schema.org/Question">
          <Question 
            onClick={() => toggleFAQ(index)}
            aria-expanded={openIndex === index} // För tillgänglighet
            aria-controls={`faq-answer-${index}`} // Koppling till svar
          >
            <span itemProp="name">{item.question}</span>
            <ToggleIcon expanded={openIndex === index}>
              {openIndex === index ? <DownArrow /> : <RightArrow />}
            </ToggleIcon>
          </Question>
          <Answer
            id={`faq-answer-${index}`}
            expanded={openIndex === index}
            itemProp="acceptedAnswer"
            itemScope
            itemType="https://schema.org/Answer"
          >
            <span itemProp="text">{item.answer}</span>
          </Answer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQ;

