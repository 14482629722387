import styled from 'styled-components';
import { FiExternalLink } from 'react-icons/fi';

export const PlatformsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  background: #f7fbff;

  @media (max-width: 768px) {
    padding: 5px 0;
  }
`;

export const PlatformLogo = styled.img`
  width: 250px;
  height: auto;
  margin: 5px;
  margin-bottom: 20px;
  filter: none; /* Tar bort filter för naturligare färg */

  @media (max-width: 768px) {
    width: 150px;
    margin-bottom: 10px;
  }
`;

export const PlatformBlock = styled.div`
  background: linear-gradient(135deg, #4a90e2, #7bb3ff); /* Mörkare blåtoner */
  color: #fff;
  width: 100%;
  max-width: 900px;
  margin: 30px 0;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 20px;
    margin: 15px 0;
    box-shadow: none;
  }
`;

export const PlatformTitle = styled.h2`
  display: none;
`;

export const PlatformDescription = styled.p`
  font-size: 1.15rem;
  text-align: center;
  margin: 20px 0;
  width: 100%;
  max-width: 700px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 0.95rem;
    max-width: 90%;
    margin: 15px 0 10px;
    line-height: 1.4; /* Ökad radavstånd för bättre läsbarhet */
    padding: 0 5px; /* Lägger till lite padding för att balansera texten bättre */
  }
`;

export const ProsConsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0;
  text-align: left;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ProsConsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const ProsConsItem = styled.li`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #ffffff;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;

  &::before {
    content: '${props => (props.type === 'cons' ? '✖' : '✔')}';
    color: ${props => (props.type === 'cons' ? '#ff6b6b' : '#2ee59d')};
    font-weight: bold;
    display: inline-block;
    font-size: 1.2rem;
    width: 1.3rem;
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const ConsItem = styled(ProsConsItem)`
  &::before {
    content: '✖';
    color: #ff6b6b;
  }
`;

export const ReviewDate = styled.div`
  font-size: 0.9rem;
  color: #ffffff;
  margin-top: 10px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const ReviewButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 12px 30px;
  background-color: #eff6ff;
  color: #1e3a8a;
  border: 1px solid #d1e7ff;
  text-decoration: none;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #d1e7ff;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .icon {
    margin-left: 8px;
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

export const ProsConsTitle = styled.h3`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

