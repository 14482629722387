// src/styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(180deg, #f7fbff, #ffffff);

    color: #333;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1, h2, h3 {
    margin: 0;
    padding: 0.5rem 0;
  }

  h1 {
    font-size: 2.5rem;
    color: #333;
  }

  h2 {
    font-size: 2rem;
    color: #444;
  }

  h3 {
    font-size: 1.5rem;
    color: #555;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.75rem;
    }
    h3 {
      font-size: 1.25rem;
    }

    .container {
      padding: 0 0.5rem;
    }
  }
`;

export default GlobalStyle;

