import styled from 'styled-components';

export const ArticleContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: 2rem;
  color: #1e3a8a;
  margin-bottom: 15px;
  text-align: center;
  line-height: 1.2;
  
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const DateInfo = styled.p`
  font-size: 0.9rem;
  color: #1e3a8a;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
`;

export const ImageCaption = styled.p`
  font-size: 0.85rem;
  color: #4a5568;
  font-style: italic;
  text-align: left;
  margin-top: -5px;
  margin-bottom: 20px;
`;

export const ContentSection = styled.div`
  margin-top: 20px;
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  color: #1e3a8a;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const RelatedArticlesContainer = styled.div`
  flex: 1;
  margin-left: 30px;
  position: sticky;
  top: 120px;
  background: linear-gradient(180deg, #3b82f6, #1a73e8);
  padding: 20px;
  border-radius: 12px;
  border: 2px solid rgba(59, 130, 246, 0.3);
  height: fit-content;

  h2 {
    font-size: 1.6rem;
    margin-bottom: 10px;
    color: #ffffff;
    text-align: left;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2rem;

    li {
      margin-bottom: 10px;

      a {
        color: #ffffff;
        text-decoration: none;
        display: block;
        padding: 10px;
        border-radius: 8px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
    margin-top: 10px;
    margin-left: 0px;
  }
`;

export const MobileDivider = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    height: 3.5px;
    background-color: #0d47a1;
    margin: 20px 0;
  }
`;

