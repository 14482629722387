import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const NavbarContainer = styled.nav`
  background: linear-gradient(90deg, #1e3a8a, #3b82f6);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0 32px;
  font-family: 'Encode Sans Expanded', sans-serif;

  @media screen and (max-width: 768px) {
    padding: 0 12px;
  }
`;

export const NavLogo = styled(RouterLink)`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;

  img {
    width: 300px;
    height: auto;

    @media screen and (max-width: 768px) {
      width: 240px;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 22px;
    right: 16px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding-left: 400px;
  list-style: none;
  text-align: center;
  width: 100%;
  height: auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 0 0 0; /* Slightly move items down by 10px */
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? '0' : '-100%')};
    background: linear-gradient(180deg, #1e3a8a, #3b82f6);
    transition: all 0.3s ease;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 768px) {
    height: 80px;
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: center;
  }
`;

export const NavLinks = styled(RouterLink)`                   
  color: #fff;
  font-weight: bold;
  font-family: 'Encode Sans Expanded', sans-serif;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  font-size: 1.4rem;
  height: 100%;             
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #f3f4f6;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  svg {
    display: none;

    @media screen and (max-width: 768px) {
      display: inline-block;
      font-size: 1.6rem;
      margin-right: 10px; /* Increase this for more spacing */
      margin-left: 95px;
    }
  }

  @media screen and (max-width: 768px) {
    text-align: left;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background: #575757;
      border-radius: 0;
    }
  }
`;

