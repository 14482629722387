import styled from 'styled-components';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

// Container for the FAQ section
export const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;

  @media screen and (max-width: 768px) {
    padding: 30px 15px; // Mindre padding på mobil
  }
`;

// Header for the FAQ section
export const FAQHeader = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  color: #1a73e8;
  margin-bottom: 40px;
  margin-top: -40px;

  @media screen and (max-width: 768px) {
    font-size: 2rem; // Minskar rubrikstorlek för mobil
    margin-bottom: 20px;
  }
`;

// Individual FAQ item container with dynamic margin based on expanded state
export const FAQItem = styled.div`
  margin-bottom: ${({ expanded }) => (expanded ? '20px' : '5px')};
  transition: margin-bottom 0.3s ease;

  @media screen and (max-width: 768px) {
    margin-bottom: ${({ expanded }) => (expanded ? '15px' : '3px')}; // Mindre marginal för mobil
  }
`;

// Question hover effect (add transition and color change)
export const Question = styled.div`
  background: #1a73e8;
  color: #ffffff;
  font-weight: bold;
  padding: 20px;
  font-size: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #3b82f6;
    color: #ffffff;
  }

  @media screen and (max-width: 768px) {
    padding: 15px; // Mindre padding för mobil
    font-size: 1.1rem; // Minskar textstorlek för mobil
  }
`;

// ToggleIcon adjustment (rotate fully on click)
export const ToggleIcon = styled.div`
  font-size: 1.5rem;
  color: #ffffff;
  transition: transform 0.4s ease;
  transform: ${({ expanded }) => (expanded ? 'rotate(1deg)' : 'rotate(0deg)')};

  @media screen and (max-width: 768px) {
    font-size: 1.3rem; // Minskar ikonstorlek för mobil
  }
`;

// Answer section with adjusted colors for a more cohesive look
export const Answer = styled.div`
  background: #e0f0ff;
  color: #1a73e8;
  font-weight: bold;
  padding: 15px 20px;
  font-size: 1.1rem;
  margin-top: 5px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: max-height 0.4s ease, opacity 0.4s ease;
  max-height: ${({ expanded }) => (expanded ? '500px' : '0')};
  opacity: ${({ expanded }) => (expanded ? '1' : '0')};
  overflow: hidden;
  visibility: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};

  @media screen and (max-width: 768px) {
    padding: 10px 15px; // Mindre padding för mobil
    font-size: 1rem; // Minskar textstorlek för mobil
    max-height: ${({ expanded }) => (expanded ? '300px' : '0')}; // Mindre maxhöjd för mobil
  }
`;

// React Icons for the right and down arrows
export const RightArrow = styled(FaChevronRight)`
  color: #ffffff;
  font-size: 1.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.3rem; // Mindre ikonstorlek för mobil
  }
`;

export const DownArrow = styled(FaChevronDown)`
  color: #ffffff;
  font-size: 1.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.3rem; // Mindre ikonstorlek för mobil
  }
`;

