import styled from 'styled-components';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  background: linear-gradient(180deg, #f7fbff, #ffffff);
  color: #333;
  min-height: 100vh;
`;

export const Title = styled.h1`
  font-size: 2.8rem;
  color: #1e3a8a;
  margin-bottom: 15px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Description = styled.p`
  font-size: 1.2rem;
  color: #4a5568;
  text-align: center;
  max-width: 600px;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 0 10px;
  }
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  background: linear-gradient(180deg, #d8e8ff, #c8dcff);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
`;

export const Input = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 12px 20px;
  padding-right: 45px;
  border-radius: 6px;
  background-color: #f0f4ff;
  border: 1px solid #d0e0ff;
  color: #333;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &::placeholder {
    color: #6b7280;
  }

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.2);
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px 20px;
  padding-right: 45px;
  border-radius: 6px;
  background-color: #f0f4ff;
  border: 1px solid #d0e0ff;
  color: #333;
  font-size: 1rem;
  resize: none;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &::placeholder {
    color: #6b7280;
  }

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.2);
  }
`;

export const Icon = styled.span`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #3b82f6;
  font-size: 1.2rem;
  transition: color 0.3s ease;

  ${InputField}:focus + &,
  ${TextArea}:focus + & {
    color: #1e3a8a;
  }
`;

export const SubmitButton = styled.button`
  background-color: #3b82f6;
  color: #fff;
  padding: 12px 35px;
  font-size: 1.1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #1e3a8a;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(59, 130, 246, 0.2);
  }
`;

export const Notification = styled.div`
  position: fixed;
  top: 80px;
  background-color: ${({ type }) => (type === 'error' ? '#e53e3e' : '#48bb78')};
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  max-width: 100%;
  width: 330px; /* Default width for desktop */
  z-index: 1000;
  transform: translateX(-50%);
  left: 50%;
  animation: fadeInOut 3s ease-in-out;

  @keyframes fadeInOut {
    0%, 100% { opacity: 0; transform: translateY(-10px) translateX(-50%); }
    10%, 90% { opacity: 1; transform: translateY(0) translateX(-50%); }
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    width: 85%; /* Increased width for mobile */
    max-width: 85%;
  }
`;

