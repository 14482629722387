// Notification.js
import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Animation för fade-in och fade-out utan sidledsrörelse
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const NotificationContainer = styled.div`
  position: fixed;
  top: 80px; /* Placering just ovanför innehållet */
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ type }) => (type === 'success' ? 'rgba(76, 175, 80, 0.9)' : 'rgba(244, 67, 54, 0.9)')};
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  animation: ${fadeIn} 0.5s ease-out, ${fadeOut} 0.5s ease 2.5s forwards; /* Ren fade-in och fade-out */
`;

const Notification = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Stängs automatiskt efter 3 sekunder

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <NotificationContainer type={type}>
      {message}
    </NotificationContainer>
  );
};

export default Notification;

