// NewsElements.js

import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';

export const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  color: #333;
  overflow-x: hidden;
`;

export const Header = styled.h1`
  font-size: 2rem;
  color: #1e3a8a;
  margin-bottom: 10px;
  text-align: center;
`;

export const SubHeader = styled.p`
  font-size: 1.1rem;
  color: #4a5568;
  text-align: center;
  max-width: 800px;
  margin-bottom: 30px;
  font-weight: bold;
`;

export const ArticleList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 800px;
`;

export const ArticleItem = styled.div`
  background: linear-gradient(135deg, #1e3a8a, #3b82f6);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const ArticleTitle = styled.h2`
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 10px;
`;

export const ArticleDate = styled.p`
  font-size: 0.9rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ArticleSnippet = styled.p`
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 10px;
`;

export const ArrowWrapper = styled.div`
  color: #ffffff;
  font-size: 1.5rem;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #cfd8ff;
  }
`;

export const NewsletterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #f7fbff;
  color: #333;
  margin-top: 40px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px; /* Rundade hörn */
  border: 2px solid #3b82f6; /* Ljusare blå border */
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  color: #1e3a8a;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.8rem; // Minska storleken för mindre skärmar
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  color: #333;
  text-align: center;
  max-width: 600px;
  margin-bottom: 25px;
`;

export const BenefitsSection = styled.div`
  text-align: left;
  max-width: 600px;
  width: 90%;
  margin-bottom: 20px; /* Minskat avstånd */
  background-color: #e6f0ff;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #1e3a8a;
`;

export const BenefitItem = styled.div`
  font-size: 0.95rem;
  font-weight: 500;
  color: #1e3a8a;
  margin: 8px 0;
  display: flex;
  align-items: center;

  &::before {
    content: '✔';
    color: #1e3a8a;
    font-size: 1rem;
    margin-right: 8px;
  }
`;

export const EmailForm = styled.form`
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 600px;
  gap: 10px;
  
  @media (max-width: 480px) {
    flex-direction: row; /* Justerar för att visa input och knapp bredvid varandra */
  }
`;

export const Input = styled.input`
  flex: 1;
  padding: 12px;
  border-radius: 30px;
  border: 1px solid #d0d0d0;
  outline: none;
  font-size: 0.9rem;
  transition: border-color 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) {
    width: 70%; /* Mer plats för input */
  }
`;

export const SubmitButton = styled.button`
  background-color: #3b82f6;
  color: #fff;
  padding: 12px 24px;
  font-size: 0.9rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1e3a8a;
    transform: translateY(-2px);
  }

  @media (max-width: 480px) {
    padding: 10px 20px; /* Justering för mobil */
    width: auto;
  }
`;

export const ConfirmationText = styled.p`
  font-size: 0.85rem;
  color: #4a5568;
  text-align: center;
  max-width: 500px;
  margin-top: 15px;
  line-height: 1.4;
`;

export const LoadMoreButton = styled.button`
  background-color: #1e3a8a;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 1.5rem; /* Minskat avstånd */

  &:hover {
    background-color: #3b82f6;
    transform: translateY(-2px);
  }
`;

