import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
  NewsContainer,
  Header,
  SubHeader,
  ArticleList,
  ArticleItem,
  ArticleTitle,
  ArticleDate,
  ArticleSnippet,
  NewsletterContainer,
  Title,
  Description,
  EmailForm,
  Input,
  SubmitButton,
  BenefitsSection,
  BenefitItem,
  ConfirmationText,
  ArrowWrapper,
  LoadMoreButton
} from './NewsElements';
import { FaArrowRight } from 'react-icons/fa';
import Notification from '../Contact/Notification'; // Importera Notification-komponenten

const News = () => {
  const [articles, setArticles] = useState([]);
  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState(null); // För notifikationer
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://kryptotrading.se/api/articles')
      .then(response => response.json())
      .then(data => {
        const sortedArticles = data.sort((a, b) => new Date(b.published_date) - new Date(a.published_date));
        setArticles(sortedArticles);
      })
      .catch(error => console.error('Error fetching articles:', error));
  }, []);

  const handleArticleNavigation = (slug) => {
    navigate(`/nyheter/${slug}`);
  };

  const handleNewsletterSignup = async (event) => {
    event.preventDefault();

    if (!email.includes('@')) {
      setNotification({ message: 'Ogiltig e-postadress.', type: 'error' });
      return;
    }

    try {
      const response = await fetch('https://kryptotrading.se/api/newsletter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setNotification({ message: 'Tack för att du skrev upp dig på vårt nyhetsbrev!', type: 'success' });
        setEmail('');
      } else {
        setNotification({ message: 'Ett fel inträffade. Försök igen senare.', type: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      setNotification({ message: 'Kunde inte skicka begäran. Kontrollera din anslutning.', type: 'error' });
    }
  };

  const closeNotification = () => {
    setNotification(null);
  };

  return (
    <NewsContainer>
      <Helmet>
        <title>Nyheter - Kryptotrading.se</title>
        <meta
          name="description"
          content="Få de senaste nyheterna och uppdateringarna inom kryptovärlden. Läs om de senaste trenderna inom kryptotrading och marknadsutvecklingar."
        />
        <meta
          name="keywords"
          content="kryptonyheter, kryptotrading, bitcoin, ethereum, kryptomarknad"
        />
        <meta property="og:title" content="Nyheter - Kryptotrading.se" />
        <meta
          property="og:description"
          content="Läs de senaste nyheterna om kryptovalutor och kryptotrading."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.kryptotrading.se/nyheter" />
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            "name": "Kryptonyheter",
            "description": "Senaste nyheterna och uppdateringarna om kryptovalutor och kryptotrading.",
            "itemListElement": articles.map((article, index) => ({
              "@type": "ListItem",
              "position": index + 1,
              "name": article.title,
              "url": `https://www.kryptotrading.se/nyheter/${article.slug}`,
              "description": article.preview,
              "datePublished": article.published_date,
            }))
          })}
        </script>
      </Helmet>

      <Header>Nyheter</Header>
      <SubHeader>De senaste nyheterna och trenderna inom kryptovärlden.</SubHeader>

      <ArticleList>
        {articles.map(article => (
          <ArticleItem key={article.id} onClick={() => handleArticleNavigation(article.slug)}>
            <ArticleTitle>{article.title}</ArticleTitle>
            <ArticleDate>{new Date(article.published_date).toLocaleDateString()}</ArticleDate>
            <ArticleSnippet>{article.preview}</ArticleSnippet>
            <ArrowWrapper>
              <FaArrowRight />
            </ArrowWrapper>
          </ArticleItem>
        ))}
      </ArticleList>

      <LoadMoreButton>Ladda fler nyheter</LoadMoreButton>

      <NewsletterContainer>
        <Title>Håll dig uppdaterad</Title>
        <Description>Få exklusiva erbjudanden, insikter och de senaste nyheterna inom kryptotrading direkt i din inkorg. Missa inte en sekund i kryptovärlden!</Description>
        
        <BenefitsSection>
          <BenefitItem>Exklusiva kampanjer och kryptoerbjudanden</BenefitItem>
          <BenefitItem>Uppdateringar om de senaste trenderna inom kryptotrading</BenefitItem>
          <BenefitItem>Inga spamtjänster – vi skickar bara relevant innehåll</BenefitItem>
          <BenefitItem>Avprenumerera när som helst, enligt GDPR</BenefitItem>
        </BenefitsSection>
        
        <EmailForm onSubmit={handleNewsletterSignup}>
          <Input
            type="email"
            placeholder="Ange din e-postadress"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <SubmitButton>Prenumerera</SubmitButton>
        </EmailForm>
        
        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={closeNotification}
          />
        )}

        <ConfirmationText>
          Genom att registrera dig godkänner du att vi kontaktar dig med erbjudanden och uppdateringar. Du kan när som helst avsluta prenumerationen.
        </ConfirmationText>
      </NewsletterContainer>
    </NewsContainer>
  );
};

export default News;

