// src/components/Navbar/index.js

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async'; // Uppdaterad import
import { FaBars, FaTimes, FaNewspaper, FaEnvelope } from 'react-icons/fa';
import { IoMdPlayCircle } from 'react-icons/io';
import { MdOutlineNetworkWifi } from 'react-icons/md';
import { NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks } from './NavbarElements';
import GlobalStyle from '../../styles/GlobalStyles'; // Justera sökvägen om nödvändigt

// Importera SVG-logotypen
import logo from '../../images/branding/kryptotrading.svg';

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  // Strukturerad data för Navbar
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Kryptotrading.se",
    url: "https://www.kryptotrading.se",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://www.kryptotrading.se/sok?q={search_term_string}",
      "query-input": "required name=search_term_string"
    },
    mainEntity: [
      {
        "@type": "WebPage",
        name: "Kom igång",
        url: "https://www.kryptotrading.se/kom-igang"
      },
      {
        "@type": "WebPage",
        name: "Plattformar",
        url: "https://www.kryptotrading.se/plattformar"
      },
      {
        "@type": "WebPage",
        name: "Nyheter",
        url: "https://www.kryptotrading.se/nyheter"
      },
      {
        "@type": "WebPage",
        name: "Kontakt",
        url: "https://www.kryptotrading.se/kontakt"
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>KryptoTrading - Din guide till kryptovalutahandel</title>
        <meta
          name="description"
          content="Kryptotrading.se hjälper dig att komma igång med kryptohandel. Upptäck de bästa plattformarna, nyheter och användbara tips."
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="KryptoTrading - Din guide till kryptovalutahandel" />
        <meta
          property="og:description"
          content="Kryptotrading.se hjälper dig att komma igång med kryptohandel. Upptäck de bästa plattformarna, nyheter och användbara tips."
        />
        <meta property="og:url" content="https://www.kryptotrading.se" />
        <meta property="og:type" content="website" />
        <link rel="icon" href="/favicon.ico" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData),
          }}
        />
      </Helmet>
      <GlobalStyle />
      <NavbarContainer role="navigation" aria-label="Huvudnavigering">
        <NavLogo to="/" onClick={closeMobileMenu} aria-label="KryptoTrading startsida">
          <img src={logo} alt="KryptoTrading Logo - Din guide till kryptovalutahandel i Sverige" loading="lazy" width="150" height="auto" />
        </NavLogo>
        <MobileIcon onClick={handleClick} aria-label="Meny">
          {click ? <FaTimes aria-hidden="true" /> : <FaBars aria-hidden="true" />}
        </MobileIcon>
        <NavMenu onClick={handleClick} click={click}>
          <NavItem>
            <NavLinks to="/kom-igang" onClick={closeMobileMenu} aria-label="Kom igång med kryptotrading">
              <IoMdPlayCircle aria-hidden="true" /> Kom igång
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/plattformar" onClick={closeMobileMenu} aria-label="Plattformar för kryptotrading">
              <MdOutlineNetworkWifi aria-hidden="true" /> Plattformar
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/nyheter" onClick={closeMobileMenu} aria-label="Nyheter om kryptotrading">
              <FaNewspaper aria-hidden="true" /> Nyheter
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/kontakt" onClick={closeMobileMenu} aria-label="Kontakta oss">
              <FaEnvelope aria-hidden="true" /> Kontakt
            </NavLinks>
          </NavItem>
        </NavMenu>
      </NavbarContainer>
    </>
  );
};

export default Navbar;

