import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, Link } from 'react-router-dom';
import {
  ArticleContainer,
  Title,
  DateInfo,
  Image,
  ImageCaption,
  ContentSection,
  Subtitle,
  Divider,
  RelatedArticlesContainer,
  MobileDivider
} from './ArticleElements';

const Article = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    fetch(`https://kryptotrading.se/api/articles/${slug}`)
      .then(response => response.json())
      .then(data => setArticle(data))
      .catch(error => console.error('Error fetching article:', error));
  }, [slug]);

  useEffect(() => {
    fetch(`https://kryptotrading.se/api/articles?exclude=${slug}`)
      .then(response => response.json())
      .then(data => setRelatedArticles(data.slice(0, 5))) // Begränsar till 5 artiklar
      .catch(error => console.error('Error fetching related articles:', error));
  }, [slug]);

  if (!article) return <p>Laddar...</p>;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: article.title,
    datePublished: article.published_date,
    dateModified: article.updated_date,
    author: {
      "@type": "Person",
      name: "Kryptotrading.se"
    },
    publisher: {
      "@type": "Organization",
      name: "Kryptotrading.se",
      logo: {
        "@type": "ImageObject",
        url: "https://www.kryptotrading.se/logo.png"
      }
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://www.kryptotrading.se/nyheter/${slug}`
    },
    image: `https://kryptotrading.se${article.image_url_desktop}`
  };

  return (
    <ArticleContainer>
      <Helmet>
        <title>{article.title} - Kryptotrading.se</title>
        <meta name="description" content={`Läs nyheten om ${article.title}.`} />
        <meta name="keywords" content={`Bitcoin, kryptovalutor, ${article.title}`} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={`Läs nyheten om ${article.title}.`} />
        <meta property="og:image" content={`https://kryptotrading.se${article.image_url_desktop}`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.kryptotrading.se/nyheter/${slug}`} />
        <meta property="article:published_time" content={article.published_date} />
        <meta property="article:modified_time" content={article.updated_date} />
        <meta property="article:author" content="Kryptotrading.se" />
        <meta property="article:section" content="Kryptonyheter" />
        <meta property="article:tag" content={`Bitcoin, kryptovalutor, ${article.title}`} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            headline: article.title,
            datePublished: article.published_date,
            dateModified: article.updated_date,
            author: {
              "@type": "Person",
              name: "Kryptotrading.se"
            },
            publisher: {
              "@type": "Organization",
              name: "Kryptotrading.se",
              logo: {
                "@type": "ImageObject",
                url: "https://www.kryptotrading.se/logo.png"
              }
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `https://www.kryptotrading.se/nyheter/${slug}`
            },
            image: {
              "@type": "ImageObject",
              url: `https://kryptotrading.se${article.image_url_desktop}`,
              height: 800,
              width: 1200
            },
            description: article.content1 || `Läs nyheten om ${article.title}.`
          })}
        </script>
      </Helmet>

      {/* Main content */}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {/* Vänster sektion */}
        <div style={{ flex: 3 }}>
          <Title>{article.title}</Title>
          <DateInfo>
            Publicerad: {new Date(article.published_date).toLocaleDateString()} |
            Uppdaterad: {new Date(article.updated_date).toLocaleDateString()}
          </DateInfo>
          <Image
            src={`https://kryptotrading.se${article.image_url_desktop}`}
            alt={article.image_caption || article.title}
          />
          <ImageCaption>{article.image_caption}</ImageCaption>

          {/* Innehållssektioner */}
          {article.subtitle1 && article.content1 && (
            <ContentSection>
              <Subtitle>{article.subtitle1}</Subtitle>
              <p>{article.content1}</p>
            </ContentSection>
          )}
          {article.subtitle2 && article.content2 && (
            <ContentSection>
              <Subtitle>{article.subtitle2}</Subtitle>
              <p>{article.content2}</p>
            </ContentSection>
          )}
          {article.subtitle3 && article.content3 && (
            <ContentSection>
              <Subtitle>{article.subtitle3}</Subtitle>
              <p>{article.content3}</p>
            </ContentSection>
          )}

          {/* Divider för mobil */}
          <MobileDivider />
        </div>

        {/* Högersidan för "Fler Artiklar" */}
        <RelatedArticlesContainer>
          <h2>Fler artiklar</h2>
          <ul>
            {relatedArticles.map((relatedArticle) => (
              <li key={relatedArticle.id}>
                <Link to={`/nyheter/${relatedArticle.slug}`} rel="noopener noreferrer">
                  {relatedArticle.title}
                </Link>
              </li>
            ))}
          </ul>
        </RelatedArticlesContainer>
      </div>
    </ArticleContainer>
  );
};

export default Article;

