// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Lägg till HelmetProvider
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Platforms from './pages/Platforms';
import GetStarted from './pages/GetStarted';
import News from './pages/News';
import Article from './pages/Article'; // Lägg till Article-sidan
import Contact from './pages/Contact';
import GlobalStyle from './styles/GlobalStyles';
import ScrollToTop from './utils/ScrollToTop';
import CookieConsent from './components/CookieConsent';

function App() {
  return (
    <HelmetProvider> {/* Omslut hela appen */}
      <Router>
        <ScrollToTop />
        <GlobalStyle />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/plattformar" element={<Platforms />} />
          <Route path="/kom-igang" element={<GetStarted />} />
          <Route path="/nyheter" element={<News />} /> {/* Nyhetslista */}
          <Route path="/nyheter/:slug" element={<Article />} /> {/* Dynamisk rutt för enskild artikel */}
          <Route path="/kontakt" element={<Contact />} />
        </Routes>
        <Footer />
        <CookieConsent />
      </Router>
    </HelmetProvider>
  );
}

export default App;

