import styled from 'styled-components';

export const HomeContainer = styled.div`
  padding: 2rem;
  background: linear-gradient(180deg, #f7fbff, #ffffff);
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
`;

export const IntroSection = styled.section`
  margin-bottom: 3rem;
  text-align: center;
  background: #4a90e2;
  color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 40px;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
  }

  p {
    font-size: 1.25rem;
    color: white;
    margin-bottom: 0;
    line-height: 1.5;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 768px) {
    max-width: 95%;
    padding: 1rem;
    margin-bottom: 1rem;
    h1 {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 0.9rem;
      line-height: 1.4;
    }
  }
`;

export const InfoHeader = styled.h2`
  text-align: center;
  color: #1a73e8;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
`;

export const InfoSection = styled.section`
  text-align: center;
  color: #2a5d9e;
  margin-bottom: 2rem;

  p {
    font-weight: bold;
    color: #2a5d9e;
    margin: 0.5rem 0;
    line-height: 1.5;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    p {
      font-size: 0.9rem;
      line-height: 1.4;
      margin: 0.25rem 0;
    }
  }
`;

export const PlatformSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 5rem;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const PlatformItem = styled.div`
  background: linear-gradient(135deg, #4a90e2, #7bb3ff);
  color: white;
  border-radius: 15px;
  display: grid;
  grid-template-columns: auto 2fr 1fr 1fr;
  align-items: center;
  padding: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 70%;
  margin: 0 auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0.8rem;
    width: 95%;
  }
`;

export const PlatformImage = styled.img`
  width: 250px;
  height: auto;
  object-fit: contain;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    width: 150px;
    margin-top: 8px; /* Endast logotypen får extra utrymme överst för mobil */
  }
`;

export const PlatformDetails = styled.div`
  padding: 0 2rem;
  color: white;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    grid-column: 1 / -1;
    align-items: center;
    text-align: center;

    h3 {
      font-size: 0.9rem;
    }
    p {
      font-size: 1.15rem;
      line-height: 1.4;
    }
  }
`;

export const BulletList = styled.ul`
  list-style: none;
  padding: 0;
  color: white;
  grid-column: 3 / 4;

  @media screen and (max-width: 768px) {
    grid-column: 1 / -1;
    text-align: center;
    padding: 0.5rem 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    @media screen and (max-width: 768px) {
      margin-left: 20px;
      margin-bottom: 0.6rem;
      padding: 0 0.5rem;
    }
  }
`;

export const BulletItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-weight: bold;

  &::before {
    content: '\\2713';
    color: #00FF00;
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    margin-right: 0.5rem;
    text-shadow: 0 0 5px #00FF00;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    &::before {
      margin-right: 0.3rem;
    }
  }
`;

export const ButtonWrapper = styled.div`
  grid-column: 4 / 5;
  justify-self: end;

  @media screen and (max-width: 768px) {
    grid-column: 1 / -1;
    justify-self: center;
    width: 100%;
  }
`;

export const PlatformLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1.5rem;
  background-color: white;
  color: #1a73e8;
  font-weight: bold;
  text-decoration: none;
  border-radius: 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 208px;

  &:hover {
    background-color: #f0f4ff;
  }

  & > svg {
    margin-left: 0.5rem;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0.4rem 1rem;
    font-size: 0.95rem;
    width: auto;
    max-width: 220px;
    margin: 0 auto;
    white-space: nowrap;
    margin-bottom: 1rem;
  }
`;

export const AboutSection = styled.section`
  background: linear-gradient(90deg, #1e3a8a, #3b82f6);
  padding: 2rem;
  border-radius: 15px;
  margin: 3rem auto;
  max-width: 70%;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
  }

  h2 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.5rem;
    }
  }

  p {
    color: #e0e0e0;
    text-align: justify;
    line-height: 1.6;
  }
`;

export const FAQSection = styled.section`
  background: linear-gradient(135deg, #4a90e2, #7bb3ff);
  padding: 2rem;
  border-radius: 15px;
  margin: 3rem auto;
  max-width: 70%;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  position: relative;

  h2 {
    text-align: center;
    color: white;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;

    svg {
      margin-right: 0.5rem;
    }
  }

  p {
    color: white;
    font-weight: 600;
    line-height: 1.6;
  }

  /* Media query för mobiloptimering */
  @media screen and (max-width: 768px) {
    max-width: 95%; /* Gör komponenten bredare för mobil */
    padding: 1.5rem;

    h2 {
      justify-content: flex-start; /* Flyttar text och ikon till vänstra hörnet på mobil */
      text-align: left;
      font-size: 1.4rem; /* Minskar storleken på texten "Kom igång" */
    }

    svg {
      font-size: 1.4rem; /* Minskar storleken på ikonen */
      margin-right: 0.3rem;
    }
  }
`;

export const FAQItem = styled.div`
  margin-bottom: 1rem;

  h3 {
    color: white;
    margin-bottom: 0.5rem;
  }

  p {
    color: white !important;
  }
`;

export const FAQHeader = styled.h3`
  color: white;
  margin-bottom: 0.5rem;
`;

export const BlogSection = styled.section`
  background: linear-gradient(135deg, #4a90e2, #7bb3ff);
  padding: 2rem;
  border-radius: 15px;
  margin: 3rem auto;
  max-width: 70%;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  position: relative;

  h2 {
    text-align: center;
    color: white;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;

    svg {
      margin-right: 0.5rem;
    }
  }

  p {
    color: white;
    font-weight: 600;
    line-height: 1.6;
  }

  /* Media query för mobiloptimering */
  @media screen and (max-width: 768px) {
    max-width: 95%; /* Gör komponenten bredare för mobil */
    padding: 1.5rem;

    h2 {
      justify-content: flex-start; /* Flyttar text och ikon till vänstra hörnet på mobil */
      text-align: left;
      font-size: 1.4rem; /* Minskar storleken på texten "Senaste Nyheter" */
    }

    svg {
      font-size: 1.4rem; /* Minskar storleken på ikonen */
      margin-right: 0.3rem;
    }
  }
`;

export const BlogItem = styled.div`
  margin-bottom: 1rem;

  a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    color: white;
    font-weight: 600;
    line-height: 1.6;
  }
`;

export const BlogHeader = styled.h3`
  color: #444;
  margin-bottom: 0.5rem;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.4rem;
  color: white;
  cursor: pointer;

  &:hover {
    color: #1e3a8a;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
    right: 1rem;
    top: 1.8rem; /* Justering för att placera pilen rätt i mobilvy */
    transform: none; /* Tar bort översättning för mobil */
  }
`;

